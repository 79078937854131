import { render, staticRenderFns } from "./EducationEventNoteForm.vue?vue&type=template&id=21791628&scoped=true&"
import script from "./EducationEventNoteForm.vue?vue&type=script&lang=ts&"
export * from "./EducationEventNoteForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21791628",
  null
  
)

export default component.exports